//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    order: {
      required: true,
    },
  },
  mounted() {
    this.$refs.popup.open();
  },
  methods: {
    async send() {
      this.$refs.popup.close();
      this.$f7.dialog.preloader("");
      try {
        await this.$store.dispatch("orders/reject", {id: this.order.id, reason: this.reason});
      } finally {
        this.$f7.dialog.close();
      }
    },
    close() {
      this.$refs.popup.close();
      this.$emit("close");
    },
    setReason(reason) {
      this.reason = reason;
    },
  },
  data: () => ({
    reason: "RUSH",
    reasons: [
      {label: "Raaka-aine loppu", value: "NO_INGREDIENT"},
      {label: "Sulkemassa", value: "CLOSING"},
      {label: "Kiire", value: "RUSH"},
    ],
  }),
};

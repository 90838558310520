import Vue from "vue";
import {format} from "date-fns";

Vue.filter("date", (value) => {
  const d = new Date(value * 1000);
  const now = new Date();
  let fmt = "dd.MM.yyyy HH.mm";
  if (now.getDate() == d.getDate() && now.getMonth() == d.getMonth() && now.getFullYear() == d.getFullYear()) {
    fmt = "HH.mm";
  }

  return format(d, fmt);
});

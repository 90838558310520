//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Radio from "@/components/Radio";
import ReportTable from "@/components/ReportTable";
import Header from "@/components/Header";
import * as dateFn from "date-fns";

export default {
  components: {
    Header,
    Radio,
    ReportTable,
  },
  data: () => ({
    busy: false,
    reportType: null,
    reportData: null,
    specificDate: null,
    startDate: null,
    endDate: null,
  }),
  computed: {
    canShow() {
      if (this.reportType === "currentDay") return true;
      if (this.reportType === "currentMonth") return true;
      if (this.reportType === "date" && this.specificDate) return true;
      if (this.reportType === "range" && this.startDate && this.endDate) return true;

      return false;
    },
  },
  methods: {
    goBack() {
      this.$f7router.back();
    },
    getOptions() {
      const FORMAT = "yyyy-MM-dd";
      if (this.reportType === "currentDay") {
        const now = dateFn.format(new Date(), FORMAT);
        return {
          start: now,
          end: now,
        };
      } else if (this.reportType === "currentMonth") {
        const now = new Date();
        const start = dateFn.startOfMonth(now);
        const end = dateFn.endOfMonth(now);
        return {
          start: dateFn.format(start, FORMAT),
          end: dateFn.format(end, FORMAT),
        };
      } else if (this.reportType === "date") {
        return {start: this.specificDate, end: this.specificDate};
      } else if (this.reportType === "range") {
        return {
          start: this.startDate,
          end: this.endDate,
        };
      }
    },
    async download() {
      if (this.busy) return;

      this.busy = true;
      const opt = this.getOptions();
      this.$f7.preloader.show();
      try {
        this.reportData = await this.$store.dispatch("orders/loadReport", opt);
        this.$f7.preloader.hide();
      } catch (e) {
        this.$f7.preloader.hide();
        this.$f7.dialog.alert("Jokin meni pieleen. Yritä hetken kuluttua uudelleen.");
      } finally {
        this.busy = false;
      }
    },
  },
};

import CustomerInfo from "./templates/CustomerView.vue";
import OrderInfo from "./templates/OrderInfo.vue";
import Orders from "./templates/OrdersView.vue";
import Welcome from "./templates/Welcome.vue";
import Login from "./templates/Login.vue";
import Reports from "./templates/Reports.vue";

const routes = [];
routes.push({path: "/login/", component: Login});
routes.push({path: "/customer/", component: CustomerInfo});
routes.push({path: "/orders/", component: Orders});
routes.push({path: "/orderInfo/:id", component: OrderInfo});
routes.push({path: "/welcome/", component: Welcome});
routes.push({path: "/reports/", component: Reports});
routes.push({path: "/", component: Login});

export default routes;


import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);
const APP_CONFIG = {
  baseUrl: "https://jjbapp.fi",
  clientId: "4GRoawH61Ev7vaDoBJqvD57bK3oY5pIa6Ayg6gn8",
  clientSecret: "USfp3TOECfagkhr5lP3qfLBlm9CtqZBkSQKWJzBl",
};

function getApiUrl(path) {
  const url = new URL(path, APP_CONFIG.baseUrl);
  return url.toString();
}

const common = {
  state: {
    time: 0,
    timeUpdateInterval: null,
  },
  actions: {
    stopTimeUpdate(ctx) {
      if (ctx.state.timeUpdateInterval) return;
      clearInterval(ctx.state.timeUpdateInterval);
    },
    startTimeUpdate(ctx) {
      if (ctx.state.timeUpdateInterval) return;

      ctx.state.timeUpdateInterval = setInterval(() => ctx.commit("updateTime"), 5000);
      ctx.commit("updateTime");
    },
  },
  mutations: {
    updateTime(state) {
      state.time = Date.now();
    },
  },
};

const auth = {
  namespaced: true,
  state: {
    userId: null,
    accessToken: null,
    refreshToken: null,
  },
  getters: {
    isLoggedIn: state => state.accessToken !== null,
    hasToken: state => state.refreshToken !== null,
  },
  mutations: {
    setCurrentUserId(state, userId) {
      state.userId = userId;
    },
    setAccessToken(state, token) {
      state.accessToken = token;
    },
    setRefreshToken(state, token) {
      state.refreshToken = token;
    }
  },
  actions: {
    async requestPasswordReset() {
      throw Error("NYI")
    },
    async loadUser(ctx) {
      const resp = await axios.get(getApiUrl("/wp-json/sa/production/users/me/"));
      ctx.commit("setCurrentUserId", resp.data.ID);
      ctx.commit("orders/setBar", resp.data.meta.barId?.[0], {root: true});
    },
    async logout(ctx) {
      ctx.dispatch("updateRefreshToken", null);
      ctx.commit("setCurrentUserId", null);
      ctx.commit("setAccessToken", null);
      window.dispatchEvent(new CustomEvent("app-auth-expired"));
    },
    async login(ctx, opt) {
      const body = new URLSearchParams();
      body.append("client_id", APP_CONFIG.clientId);
      body.append("client_secret", APP_CONFIG.clientSecret);
      body.append("grant_type", "password");
      body.append("username", opt.username);
      body.append("password", opt.password);
      const resp = await axios.post(getApiUrl("/oauth/token"), body);

      ctx.dispatch("updateAccessToken", resp.data.access_token);
      ctx.dispatch("updateRefreshToken", resp.data.refresh_token);
      await ctx.dispatch("loadUser");
      window.dispatchEvent(new CustomEvent("app-authenticated"));
    },
    async refreshAccessToken(ctx) {
      const body = new URLSearchParams();
      body.append("client_id", APP_CONFIG.clientId);
      body.append("client_secret", APP_CONFIG.clientSecret);
      body.append("grant_type", "refresh_token");
      body.append("refresh_token", ctx.state.refreshToken);

      const resp = await axios.post(getApiUrl("/oauth/token"), body);
      ctx.dispatch("updateAccessToken", resp.data.access_token);
      ctx.dispatch("updateRefreshToken", resp.data.refresh_token);
    },
    updateAccessToken(ctx, token) {
      ctx.commit("setAccessToken", token);
    },
    updateRefreshToken(ctx, token) {
      ctx.commit("setRefreshToken", token);
      if (token === null) {
        localStorage.removeItem("refreshToken");
      } else {
        localStorage.setItem("refreshToken", token);
      }
    },
    async init(ctx) {
      const token = localStorage.getItem("refreshToken");
      if (!token) return;

      console.info("auth/init: have auth");
      ctx.dispatch("updateRefreshToken", token);
      try {
        await ctx.dispatch("refreshAccessToken");
        await ctx.dispatch("loadUser");
        window.dispatchEvent(new CustomEvent("app-authenticated"));
      } catch(e) {
        console.warn("Failed to init auth", e)
        window.dispatchEvent(new CustomEvent("app-auth-expired"));
        ctx.dispatch("logout");
      }
    },
  },
};

const orders = {
  namespaced: true,
  state: {
    barId: null,
    ordersPaused: false,
    orders: [],
  },
  getters: {
    orderById: state => id => state.orders.find(order => order.id == id),
    imminent: (state, getters, rootState) => getters.accepted.filter(order => {
      const now = rootState.time / 1000;
      const diff = order.time - now;
      return diff <= 60 * 30;
    }),
    imminentIds: (state, getters) => getters.imminent.map(order => order.id),
    pending(state) {
      return state.orders.filter(order => order.status === "pending");
    },
    accepted(state) {
      return state.orders.filter(order => order.status === "accepted");
    },
    processing(state) {
      return state.orders.filter(order => order.status === "processing");
    },
    ready(state) {
      return state.orders.filter(order => order.status === "ready");
    },
  },
  mutations: {
    setBar(state, bar) {
      console.log("Using bar id", bar);
      state.barId = bar;
    },
    setOrdersPaused(state, paused) {
      state.ordersPaused = paused;
    },
    setOrders(state, orders) {
      state.orders = orders;
    },
  },
  actions: {
    async setOrdersPaused(ctx, paused) {
      const barId = ctx.state.barId;
      const url = getApiUrl(`/wp-json/jjb/v1/bars/${barId}`);
      await axios.put(url, {ordersPaused: paused});
      ctx.commit("setOrdersPaused", paused);
    },
    async loadBar(ctx) {
      const barId = ctx.state.barId;
      const url = getApiUrl(`/wp-json/jjb/v1/bars/${barId}`);
      const resp = await axios.get(url);
      ctx.commit("setOrdersPaused", resp.data.ordersPaused);
    },
    async useRewards(ctx, {customerId, rewardIds}) {
      const url = getApiUrl(`/wp-json/jjb/v1/customers/${customerId}/useRewards`);
      const resp = await axios.post(url, {rewards: rewardIds});
      return resp.data;
    },
    async useReward(ctx, {customerId, rewardId}) {
      const url = getApiUrl(`/wp-json/jjb/v1/customers/${customerId}/useReward`);
      const resp = await axios.post(url, {reward: rewardId});
      return resp.data;
    },
    async loadCustomerFromWinposId(ctx, id) {
      const url = getApiUrl(`/wp-json/jjb/v1/customers/winpos/${id}`);
      const resp = await axios.get(url);
      return resp.data;
    },
    async loadOrderCustomer(ctx, id) {
      const url = getApiUrl(`/wp-json/jjb/v1/order/${id}/customer`);
      const resp = await axios.get(url);
      return resp.data;
    },
    async accept(ctx, id) {
      const url = getApiUrl(`/wp-json/jjb/v1/order/${id}/accept`);
      await axios.post(url);
      await ctx.dispatch("load");
    },
    async updateStatus(ctx, {id, status}) {
      const url = getApiUrl(`/wp-json/jjb/v1/order/${id}/status`);
      await axios.post(url, {status});
      await ctx.dispatch("load");
    },
    async reject(ctx, {id, reason}) {
      const url = getApiUrl(`/wp-json/jjb/v1/order/${id}/reject`);
      await axios.post(url, {reason});
      await ctx.dispatch("load");
    },
    async load(ctx) {
      const barId = ctx.state.barId;
      if (!barId) return;

      const url = getApiUrl(`/wp-json/jjb/v1/order/${barId}/load`);
      const resp = await axios.get(url);
      ctx.commit("setOrders", resp.data);
    },
    async loadReport(ctx, opt) {
      const barId = ctx.state.barId;

      const url = getApiUrl(`/wp-json/jjb/v2/reports/orders`);
      const resp = await axios.post(url, {
        barId,
        format: "json",
        ...opt,
      });

      return resp.data;
    },
  },
};

export const store = new Vuex.Store({
  ...common,
  modules: {
    auth,
    orders,
  },
});

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {ErrorMixin} from "@/mixins";
import Rewards from "@/components/Rewards.vue";

export default {
  mixins: [ErrorMixin],
  components: {
    Rewards,
  },
  props: {
    customer: {
      required: true,
    },
  },
  methods: {
    returnBack() {
      this.$f7.views.main.router.back();
    },
  },
};

//
//
//
//
//
//
//
//
//
//

import logger from "@/loggerInstance";

export default {
  created() {
    logger.log("INFO", "Welcome.vue");
  },
  methods: {
    proceed() {
      this.$f7router.navigate("/orders/", {clearPreviousHistory: true, reloadAll: true});
      logger.log("INFO", "Welcome.vue proceed()");
    },
  },
};

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "f7-page",
    [
      _vm.customer
        ? [
            _c(
              "f7-block",
              { staticClass: "single-order header outer-container" },
              [
                _c(
                  "f7-row",
                  { staticClass: "order-navigation-container no-padding" },
                  [
                    _c(
                      "f7-col",
                      {
                        staticClass: "no-padding order-details-nav",
                        attrs: { width: "100" },
                        on: {
                          click: function ($event) {
                            return _vm.returnBack()
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("CUSTOMER")) +
                            "\n        "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "info-wrapper" }, [
              _c("div", { staticClass: "loyalty-logo-container" }, [
                _c("img", {
                  staticClass: "loyalty-logo",
                  attrs: { src: _vm.customer.loyalty.level.image },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "customer-information" }, [
                _c("span", { staticClass: "name" }, [
                  _vm._v(
                    _vm._s(_vm.customer.firstName) +
                      " " +
                      _vm._s(_vm.customer.lastName)
                  ),
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "phone" }, [
                  _vm._v(_vm._s(_vm.customer.phone)),
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "lvl-name" }, [
                  _vm._v(_vm._s(_vm.customer.loyalty.level.name)),
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "coconuts" }, [
                  _vm._v(_vm._s(_vm.customer.loyalty.points) + " kookosta"),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("Rewards", { attrs: { columns: 3, customer: _vm.customer } }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
//
//
//
//
//
//
//
//
//

import Order from "./Order";

export default {
  components: {
    Order,
  },
  props: {
    items: {
      required: true,
    },
  },
};

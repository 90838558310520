export class Logger {

  constructor(tag, url, flushInterval) {
    this.tag = tag;
    this.serverURL = url;
    this.flushInterval = flushInterval;
    this.metaData = {};
    this.messages = [];

    setInterval(() => this.flush(), this.flushInterval);
  }

  addMetaData(key, value) {
    this.metaData[key] = value;
  }

  removeMetaData(key) {
    delete this.metaData[key];
  }

  log(level, message, data) {
    const formattedMessage = `[${this.tag}][${level}] ${message}`;
    const finalData = {...this.metaData, ...data};
    console.log(formattedMessage, finalData);

    this.messages.push({level, message: message.toString(), data: finalData});
  }

  async flush() {
    if (!this.messages.length) return;

    const body = JSON.stringify({messages: this.messages});
    this.messages = [];
    const resp = await fetch(this.serverURL, {
      method: "POST",
      body,
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (resp.status !== 200) {
      console.warn("Failed to flush logs to server.", resp.status);
    }
  }

}

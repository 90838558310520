var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "actions" }, [
    _c("div", { staticClass: "order-pause-status" }, [
      !_vm.ordersPaused
        ? _c(
            "button",
            {
              staticClass: "action-btn pause-btn",
              on: {
                click: function ($event) {
                  return _vm.pauseOrders(true)
                },
              },
            },
            [_vm._v("\n      " + _vm._s(_vm.$t("PAUSE_ORDERS")) + "\n    ")]
          )
        : _c(
            "button",
            {
              staticClass: "action-btn unpause-btn",
              on: {
                click: function ($event) {
                  return _vm.pauseOrders(false)
                },
              },
            },
            [_vm._v("\n      TAUKOTILA PÄÄLLÄ\n    ")]
          ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "action-btn reports-btn",
          on: { click: _vm.viewReports },
        },
        [_vm._v("\n      Raportit\n    ")]
      ),
      _vm._v(" "),
      _c(
        "button",
        { staticClass: "action-btn logout-btn", on: { click: _vm.logout } },
        [_vm._v("\n      Kirjaudu ulos\n    ")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
export default {
  ORDERS_NEW: "Uudet",
  ORDERS_QUEUED: "Jonossa",
  ORDERS_PROCESSING: "Teossa",
  ORDERS_READY: "Valmiit",
  PAUSE_ORDERS: "Aseta tilaukset tauolle",
  NO_ORDERS: "Ei tilauksia",
  CURRENT_CUSTOMER: "Kirjautunut asiakas",
  CUSTOMER: "Asiakas",
  REWARDS_TO_USE: "Lunastettavat edut",
  OTHER_REWARDS: "Muut edut",
  USE_SELECTED_REWARDS: "Lunasta valitut edut",

  REWARDS: "Edut",
  PICKUP: "Haku",
  ADDITIONAL_INFO: "Lisätietoja",
  ACCEPT_ORDER: "Kuittaa tilaus",
  REJECT_ORDER: "Hylkää",
  ORDER_SET_WIP: "Siirrä tekoon",
  ORDER_SET_READY: "Kuittaa valmiiksi",

  LOGIN_FAILED: "Kirjautuminen antamallasi salasanalla ei onnistu",
  LOGIN_FAILED_TITLE: "Salasana väärin",

  ERR_GENERIC: "Jokin meni pieleen",
  ERR_NO_CREDITS: "Käyttäjällä ei ole tarpeeksi saldoa. Tilaus on hylätty.",

  CONTINUE_APP: "Jatka",
};

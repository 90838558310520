var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "table-wrapper" }, [
    _c(
      "table",
      { staticClass: "report-table" },
      [
        _vm._m(0),
        _vm._v(" "),
        _vm._l(_vm.data, function (row) {
          return _c("tr", [
            _c("td", [_vm._v(_vm._s(row.orderId))]),
            _vm._v(" "),
            _c("td", [_vm._v(_vm._s(row.time))]),
            _vm._v(" "),
            _c("td", [_vm._v(_vm._s(row.customer))]),
            _vm._v(" "),
            _c("td", [_vm._v(_vm._s(row.total) + " €")]),
            _vm._v(" "),
            _c("td", [_vm._v(_vm._s(row.productCategory))]),
            _vm._v(" "),
            _c("td", [_vm._v(_vm._s(row.productName))]),
            _vm._v(" "),
            _c("td", [_vm._v(_vm._s(row.productSize))]),
            _vm._v(" "),
            _c("td", [_vm._v(_vm._s(row.discount))]),
            _vm._v(" "),
            _c("td", [_vm._v(_vm._s(row.discountName))]),
            _vm._v(" "),
            _c("td", [_vm._v(_vm._s(row.rejectReason))]),
            _vm._v(" "),
            _c("td", [_vm._v(_vm._s(row.rejectTime))]),
          ])
        }),
        _vm._v(" "),
        _c("tr", [
          _c("th", { attrs: { colspan: "2" } }, [_vm._v("YHTEENSÄ")]),
          _vm._v(" "),
          _c("td", { attrs: { colspan: "9" } }, [
            _vm._v(_vm._s(_vm.totals) + " €"),
          ]),
        ]),
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", [_vm._v("Tilaus#")]),
      _vm._v(" "),
      _c("th", [_vm._v("Aika")]),
      _vm._v(" "),
      _c("th", [_vm._v("Asiakas")]),
      _vm._v(" "),
      _c("th", [_vm._v("Summa")]),
      _vm._v(" "),
      _c("th", [_vm._v("Tuotekategoria")]),
      _vm._v(" "),
      _c("th", [_vm._v("Tuote")]),
      _vm._v(" "),
      _c("th", [_vm._v("Koko")]),
      _vm._v(" "),
      _c("th", [_vm._v("Alennus")]),
      _vm._v(" "),
      _c("th", [_vm._v("Alennuksen nimi")]),
      _vm._v(" "),
      _c("th", [_vm._v("Hylkäyssyy")]),
      _vm._v(" "),
      _c("th", [_vm._v("Hylkäysaika")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("f7-page", [
    _c(
      "div",
      { staticClass: "page-wrapper" },
      [
        _c("Header", {
          attrs: { "show-navigation": "" },
          on: { changeTab: _vm.setTab },
        }),
        _vm._v(" "),
        _c("NewOrderAlert"),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "page-content" },
          [
            _c("OrderViewActions"),
            _vm._v(" "),
            _vm.scannedCustomer
              ? _c("CustomerCard", {
                  attrs: { customer: _vm.scannedCustomer },
                  on: {
                    close: function ($event) {
                      _vm.scannedCustomer = null
                    },
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _c("Queue", { attrs: { items: _vm.currentQueue } }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("f7-page", [
    _c("div", { staticClass: "login-outer-container" }, [
      _c(
        "div",
        { staticClass: "login-inner-container" },
        [
          _c(
            "f7-block",
            [
              _c("f7-block-title", [_vm._v("Kirjautuminen")]),
              _vm._v(" "),
              _c(
                "f7-list",
                { attrs: { inset: "" } },
                [
                  _c("f7-list-input", {
                    attrs: {
                      type: "text",
                      placeholder: "Käyttäjätunnus",
                      "clear-button": "",
                    },
                    on: {
                      input: function ($event) {
                        _vm.username = $event.target.value
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("f7-list-input", {
                    attrs: {
                      type: "password",
                      placeholder: "Salasana",
                      "clear-button": "",
                    },
                    on: {
                      input: function ($event) {
                        _vm.password = $event.target.value
                      },
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "f7-block",
                [
                  _c(
                    "f7-button",
                    {
                      attrs: { fill: "", big: "" },
                      on: {
                        click: function ($event) {
                          return _vm.login()
                        },
                      },
                    },
                    [_vm._v("Kirjaudu sisään")]
                  ),
                  _c("br"),
                  _vm._v(" "),
                  _c(
                    "f7-button",
                    {
                      attrs: { small: "" },
                      on: {
                        click: function ($event) {
                          return _vm.forgetPw()
                        },
                      },
                    },
                    [_vm._v("Unohtuiko salasana?")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("f7-block", { staticClass: "languages" }, [
                _c("button", {
                  staticClass: "fi lang-btn",
                  on: {
                    click: function ($event) {
                      return _vm.setLang("fi")
                    },
                  },
                }),
                _vm._v(" "),
                _c("button", {
                  staticClass: "sv lang-btn",
                  on: {
                    click: function ($event) {
                      return _vm.setLang("sv")
                    },
                  },
                }),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
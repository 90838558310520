var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "f7-page",
    [
      _c(
        "f7-block",
        { staticClass: "single-order header outer-container" },
        [
          _c(
            "f7-row",
            { staticClass: "order-navigation-container no-padding" },
            [
              _c(
                "f7-col",
                {
                  staticClass: "no-padding order-details-nav",
                  attrs: { width: "100" },
                  on: {
                    click: function ($event) {
                      return _vm.returnBack()
                    },
                  },
                },
                [_vm._v("\n        tilaustiedot\n      ")]
              ),
              _vm._v(" "),
              _c(
                "f7-col",
                {
                  staticClass: "no-padding red-bg text-align-center",
                  attrs: { width: "100" },
                },
                [
                  _c(
                    "f7-block",
                    { staticClass: "no-padding color-white" },
                    [
                      _c(
                        "f7-row",
                        [
                          _c(
                            "f7-col",
                            {
                              staticClass: "no-padding",
                              attrs: { width: "50" },
                            },
                            [
                              _c(
                                "f7-block",
                                { staticClass: "no-padding order-number" },
                                [
                                  _c("h4", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("date")(_vm.order.createdAt)
                                      )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("h1", [_vm._v(_vm._s(_vm.order.id))]),
                                  _vm._v(" "),
                                  _c("h4", [
                                    _vm._v(
                                      _vm._s(_vm.$t("PICKUP")) +
                                        " " +
                                        _vm._s(_vm._f("date")(_vm.order.time))
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.customer
                            ? _c(
                                "f7-col",
                                {
                                  staticClass: "no-padding",
                                  attrs: { width: "50" },
                                },
                                [
                                  _c(
                                    "f7-block",
                                    { staticClass: "no-padding order-number" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "lion-container" },
                                        [
                                          _c("img", {
                                            staticClass: "current-level",
                                            attrs: {
                                              src: _vm.customer.loyalty.level
                                                .image,
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("h3", [
                                            _vm._v(
                                              _vm._s(_vm.customer.firstName) +
                                                " " +
                                                _vm._s(_vm.customer.lastName)
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("h3", [
                                            _vm._v(_vm._s(_vm.customer.phone)),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "f7-block",
        { staticClass: "single-order outer-container list-view" },
        [
          _c(
            "f7-block",
            { staticClass: "inner-container no-padding" },
            [
              _c(
                "f7-row",
                { staticClass: "list-container" },
                _vm._l(_vm.order.items, function (item) {
                  return _c(
                    "f7-col",
                    {
                      staticClass: "no-padding list-item-single-outer",
                      attrs: { width: "100" },
                    },
                    [
                      _c(
                        "f7-block",
                        { staticClass: "no-padding list-item-single" },
                        [
                          _c(
                            "f7-row",
                            { staticClass: "no-padding" },
                            [
                              _c(
                                "f7-col",
                                { staticClass: "id-col" },
                                [
                                  _c(
                                    "f7-block",
                                    { staticClass: "no-padding" },
                                    [
                                      _c(
                                        "f7-row",
                                        { staticClass: "no-padding" },
                                        [
                                          _c(
                                            "f7-col",
                                            { staticClass: "no-padding" },
                                            [
                                              _c("img", {
                                                attrs: { src: item.image.url },
                                              }),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("f7-col", { staticClass: "text-col" }, [
                                _c("div", { staticClass: "name" }, [
                                  _c("h4", [
                                    _vm._v(
                                      _vm._s(item.name) +
                                        " x " +
                                        _vm._s(item.quantity)
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                item.ingredients.length
                                  ? _c("div", { staticClass: "ingredients" }, [
                                      _c("h4", [
                                        _vm._v(
                                          "\n                    Ainesosat:\n                    " +
                                            _vm._s(
                                              _vm._f("ingredients")(
                                                item.ingredients
                                              )
                                            ) +
                                            "\n                  "
                                        ),
                                      ]),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                item.extras.length
                                  ? _c("div", { staticClass: "extras" }, [
                                      _c("h4", [
                                        _vm._v(
                                          "Lisät: " +
                                            _vm._s(
                                              _vm._f("extras")(item.extras)
                                            )
                                        ),
                                      ]),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                item.info
                                  ? _c("div", { staticClass: "info" }, [
                                      _vm._v(
                                        _vm._s(_vm.$t("ADDITIONAL_INFO")) +
                                          ": " +
                                          _vm._s(item.info)
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("div", { staticClass: "advantages" }, [
                                  _c("h4", [_vm._v(_vm._s(item.advantages))]),
                                ]),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from "vuex";

export default {
  data: () => ({
    currentTab: "pending",
  }),
  computed: {
    ...mapGetters("orders", ["pending", "processing", "ready", "accepted", "imminent"]),
  },
  methods: {
    tabClass(tab) {
      const active = this.currentTab === tab;
      let alert = false;
      if (tab === "accepted") {
        alert = this.imminent.length > 0;
      }

      return {active, alert};
    },
    setTab(tab) {
      this.currentTab = tab;
      this.$emit("changeTab", tab);
    },
  },
};

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "header",
    { staticClass: "app-header" },
    [
      _vm._m(0),
      _vm._v(" "),
      _vm.showNavigation
        ? _c("Navigation", {
            on: {
              changeTab: function ($event) {
                return _vm.$emit("changeTab", $event)
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "logo-container" }, [
      _c("img", {
        staticClass: "logo",
        attrs: { src: require("@img/JJB-ROUND-RGB.svg") },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
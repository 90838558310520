var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "order" },
    [
      _vm.showRejectModal
        ? _c("RejectModal", {
            attrs: { order: _vm.order },
            on: {
              close: function ($event) {
                _vm.showRejectModal = false
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "info", on: { click: _vm.goToOrder } }, [
        _c("span", { staticClass: "time" }, [
          _vm._v(_vm._s(_vm._f("date")(_vm.order.createdAt))),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "order-id" }, [
          _vm._v("#" + _vm._s(_vm.order.id)),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "items" },
        [
          _c(
            "span",
            { staticClass: "pickup", class: { alert: _vm.isImminent } },
            [
              _vm._v(
                _vm._s(_vm.$t("PICKUP")) +
                  " " +
                  _vm._s(_vm._f("date")(_vm.order.time))
              ),
            ]
          ),
          _vm._v(" "),
          _vm.order.customer
            ? _c("span", { staticClass: "customer-detail" }, [
                _c("span", { staticClass: "customer-detail-label" }, [
                  _vm._v(_vm._s(_vm.$t("CUSTOMER")) + ":"),
                ]),
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.order.customer.firstName) +
                    " " +
                    _vm._s(_vm.order.customer.lastName) +
                    "\n      -\n      " +
                    _vm._s(_vm.order.customer.phone) +
                    "\n    "
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.order.items, function (item) {
            return _c(
              "div",
              { staticClass: "order-item" },
              [
                _c("span", { staticClass: "product-name product-name-main" }, [
                  _vm._v(
                    _vm._s(item.quantity) +
                      "x " +
                      _vm._s(item.name) +
                      " - " +
                      _vm._s(_vm._f("price")(item.total)) +
                      "€"
                  ),
                ]),
                _vm._v(" "),
                _vm._l(item.extras, function (extra) {
                  return _c("span", { staticClass: "extra" }, [
                    _vm._v(
                      "\n        +" +
                        _vm._s(extra.name) +
                        " - " +
                        _vm._s(_vm._f("price")(extra.total)) +
                        "€\n      "
                    ),
                  ])
                }),
                _vm._v(" "),
                _vm.hasRewards(item)
                  ? _c("span", { staticClass: "item-info" }, [
                      _vm._v(
                        "\n        Käytetyt edut: " +
                          _vm._s(_vm.formatAppliedRewards(item)) +
                          "\n      "
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                item.info
                  ? _c("span", { staticClass: "item-info" }, [
                      _vm._v(
                        _vm._s(_vm.$t("ADDITIONAL_INFO")) +
                          ": " +
                          _vm._s(item.info)
                      ),
                    ])
                  : _vm._e(),
              ],
              2
            )
          }),
          _vm._v(" "),
          _c("div", { staticClass: "order-total" }, [
            _vm._v(
              "\n      Yhteensä " +
                _vm._s(_vm._f("price")(_vm.order.total)) +
                "€\n    "
            ),
          ]),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "actions" },
        [
          _vm.order.status === "pending"
            ? [
                _c(
                  "button",
                  { staticClass: "btn-positive", on: { click: _vm.accept } },
                  [_vm._v(_vm._s(_vm.$t("ACCEPT_ORDER")))]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  { staticClass: "btn-secondary", on: { click: _vm.reject } },
                  [_vm._v(_vm._s(_vm.$t("REJECT_ORDER")))]
                ),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.order.status === "accepted"
            ? [
                _c(
                  "button",
                  {
                    staticClass: "btn-positive",
                    on: {
                      click: function ($event) {
                        return _vm.setStatus("processing")
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("ORDER_SET_WIP")))]
                ),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.order.status === "processing"
            ? [
                _c(
                  "button",
                  { staticClass: "btn-positive", on: { click: _vm.setReady } },
                  [_vm._v(_vm._s(_vm.$t("ORDER_SET_READY")))]
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "queue-view" }, [
    _c(
      "div",
      { staticClass: "inner-view" },
      [
        !_vm.items.length
          ? _c("div", { staticClass: "no-orders" }, [
              _vm._v(_vm._s(_vm.$t("NO_ORDERS"))),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm._l(_vm.items, function (order) {
          return _c("Order", { key: order.id, attrs: { order: order } })
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
//
//
//
//
//
//
//
//

export default {
  model: {
    prop: "currentValue",
    event: "change",
  },
  inheritAttrs: false,
  props: {
    currentValue: undefined,
    value: undefined,
    label: String,
  },
  methods: {
    change() {
      this.$emit("change", this.value);
    },
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CustomerCard from "@/components/CustomerCard";
import Header from "@/components/Header";
import Queue from "@/components/Queue";
import OrderViewActions from "@/components/OrderViewActions";
import NewOrderAlert from "@/components/NewOrderAlert";
import logger from "@/loggerInstance";

export default {
  components: {
    Header,
    Queue,
    CustomerCard,
    OrderViewActions,
    NewOrderAlert,
  },
  data: () => ({
    scannedCustomer: null,
    queueName: "pending",
    orderLoadInterval: null,
    barUpdateInterval: null
  }),
  computed: {
    currentQueue() {
      return this.$store.getters[`orders/${this.queueName}`];
    },
  },
  created() {
    this.$store.dispatch("orders/load");
    this.barUpdateInterval = setInterval(() => this.$store.dispatch("orders/loadBar"), 30000);
    this.orderLoadInterval = setInterval(() => this.$store.dispatch("orders/load"), 20000);
    document.addEventListener("scan", this.onQrScan);

    window.__qrScanMock = this.onQrScan;
    window.__loadCustomer = this.loadCustomer;
  },
  beforeDestroy() {
    clearInterval(this.orderLoadInterval);
    clearInterval(this.barUpdateInterval);
    document.removeEventListener("scan", this.onQrScan);
  },
  methods: {
    manualLoadCustomer() {
      this.$f7.dialog.prompt("Syötä asiakasnumero", "", value => {
        this.loadCustomer(`superapp${value}`);
      });
    },
    async loadCustomer(code) {
      try {
        this.scannedCustomer = await this.$store.dispatch("orders/loadCustomerFromWinposId", code);
      } catch (e) {
        logger.log("ERROR", "Failed to scan customer qr", {code, error: e.message});
        this.$f7.dialog.alert("Asiakkaan skannaus epäonnistui", "Virhe");
      }
    },
    onQrScan(event) {
      const code = event.detail.scanCode;
      return this.loadCustomer(code);
    },
    setTab(e) {
      this.queueName = e;
    },
  },
};

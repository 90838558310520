var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "rewards" }, [
    _c("h2", { staticClass: "rewards-title" }, [
      _vm._v(_vm._s(_vm.$t("REWARDS_TO_USE"))),
    ]),
    _vm._v(" "),
    _c(
      "ul",
      { ref: "listContainer", staticClass: "rewards-list" },
      _vm._l(_vm.usableRewards, function (reward) {
        return _c("li", { key: reward.id }, [
          _c(
            "button",
            {
              staticClass: "reward-button",
              on: {
                click: function ($event) {
                  return _vm.toggleReward(reward)
                },
              },
            },
            [
              _c("span", { staticClass: "reward-button-validity" }, [
                _vm._v(
                  "Voimassa " + _vm._s(_vm.rewardValidity(reward.expiresAt))
                ),
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "reward-button-name" }, [
                _vm._v(
                  "\n          " + _vm._s(reward.reward.name) + "\n        "
                ),
              ]),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "reward-button-selection",
                  class: { visible: _vm.isRewardSelected(reward) },
                },
                [_vm._v("\n          ✓\n        ")]
              ),
            ]
          ),
        ])
      }),
      0
    ),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "redeem-rewards",
        attrs: { disabled: !_vm.canRedeem },
        on: { click: _vm.redeemSelected },
      },
      [_vm._v("\n    " + _vm._s(_vm.$t("USE_SELECTED_REWARDS")) + "\n  ")]
    ),
    _vm._v(" "),
    _c("h2", { staticClass: "rewards-title" }, [
      _vm._v(_vm._s(_vm.$t("OTHER_REWARDS"))),
    ]),
    _vm._v(" "),
    _c("span", { staticClass: "other-rewards" }, [
      _vm._v(_vm._s(_vm.otherRewards)),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    data: Array,
  },
  computed: {
    totals() {
      const total = this.data.reduce((sum, row) => sum + Number(row.total) * 100, 0);
      return total / 100;
    },
  },
};

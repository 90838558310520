//
//
//
//
//
//
//
//
//
//

import Navigation from "./Navigation";

export default {
  components: {
    Navigation,
  },
  props: {
    showNavigation: Boolean,
  },
};

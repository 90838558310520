export default {
  ORDERS_NEW: "Nya",
  ORDERS_QUEUED: "I kö",
  ORDERS_PROCESSING: "I produktion",
  ORDERS_READY: "Färdiga",
  PAUSE_ORDERS: "Lägg beställningen på paus",
  NO_ORDERS: "Inga beställningar",
  CURRENT_CUSTOMER: "Inloggad kund",
  CUSTOMER: "Kund",
  REWARDS_TO_USE: "Inlösbara förmåner",
  REWARDS: "Förmåner",
  PICKUP: "Upphämtningstid",
  ADDITIONAL_INFO: "Tilläggsuppgifter",
  ACCEPT_ORDER: "Bekfärta beställingen",
  REJECT_ORDER: "Avvisa beställingen",
  ORDER_SET_WIP: "Påbörja beställningen",
  ORDER_SET_READY: "Bekräfta färdigt",

  LOGIN_FAILED: "Det går inte att logga in med angett lösenord",
  LOGIN_FAILED_TITLE: "Ogiltigt lösenord",

  ERR_GENERIC: "Något gick fel",
  ERR_NO_CREDITS: "Användaren har inte tillräckligt med saldo. Beställningen nekades.",

  CONTINUE_APP: "Fortsätta",
};

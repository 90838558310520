import "framework7-icons";
import "material-design-icons/iconfont/material-icons.css";
import "framework7/css/framework7.bundle.css";
import Framework7 from "framework7/framework7.esm.bundle.js";
import Framework7Vue from "framework7-vue/framework7-vue.esm.bundle.js";
import App from "./app";
import Vue from "vue";
import Vuex from "vuex";
import VueI18n from "vue-i18n";
import axios from "axios";
import "./css/app.scss";
import onscan from "onscan.js";
import "./filters";
import translations from "./translations";
import {store} from "./store/store.js";
import logger from "./loggerInstance";


axios.interceptors.response.use(
  resp => resp,
  async err => {
    if (err.request.status !== 401) return Promise.reject(err);

    if (!store.getters["auth/hasToken"]) {
      store.dispatch("auth/logout");
      return;
    }

    try {
      await store.dispatch("auth/refreshAccessToken");
      return axios(err.response.config);
    } catch(e) {
      return Promise.reject("Failed to update auth");
    }
  }
);

axios.interceptors.request.use(config => {
  if (config.url.endsWith("/oauth/token")) return config;

  if (store.getters["auth/isLoggedIn"]) {
    config.headers.Authorization = `Bearer ${store.state.auth.accessToken}`;
  }
  return config;
});

onscan.attachTo(document);

Vue.config.errorHandler = (err) => {
  logger.log("ERROR", err.message, {stack: err.stack});
};

window.addEventListener("unhandledrejection", ev => {
  logger.log("ERROR", ev.reason);
});

window.addEventListener("error", ev => {
  logger.log("ERROR", ev.message, {error: ev.error});
});


Vue.use(Vuex);
Vue.use(VueI18n);
Framework7.use(Framework7Vue);

let lang = localStorage.getItem("currentLanguage");
if (!lang || lang == "en") lang = "fi";

const i18n = new VueI18n({
  locale: lang,
  messages: translations,
});

// Init App
const app = new Vue({
  el: "#app",
  render: h => h(App),
  pushState: true,
  store,
  i18n,
  async mounted() {
    this.$store.dispatch("auth/init");
    await this.$nextTick();

    const loggedIn = this.$store.getters["auth/hasToken"];
    const path = loggedIn ? "/welcome/" : "/";
    this.$f7.views.main.router.navigate(path, {clearPreviousHistory: true, reloadAll: true});
  },
  components: {
    app: App,
  },
});

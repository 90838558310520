var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("label", { staticClass: "radio-button" }, [
    _c("input", {
      attrs: { type: "radio" },
      domProps: { checked: _vm.currentValue === _vm.value },
      on: { change: _vm.change },
    }),
    _vm._v(" "),
    _c("span", { staticClass: "radio--indicator" }),
    _vm._v(" "),
    _c("span", { staticClass: "radio--label" }, [_vm._v(_vm._s(_vm.label))]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
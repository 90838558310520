export function getProp(obj, keyPath) {
  const pathComponents = keyPath.split(".");

  let item = obj;
  for (const component of pathComponents) {
    item = item[component];
    if (item === undefined || item === null) {
      return item;
    }
  }

  return item;
}

const byteLookup = Array(256).fill(0)
  .map((_, index) => index.toString(16).padStart(2, "0"));

/**
 *
 * @param {Uint8Array} buff
 * @param {number} ind
 * @param {number} len
 * @returns
 */
function byteStr(buff, ind, len) {
  const slice = buff.slice(ind, ind + len);
  return Array.from(slice).map(b => byteLookup[b])
    .join("");
}

export function genUUID() {
  const buff = new Uint8Array(16);
  window.crypto.getRandomValues(buff);

  buff[6] |= 0b01000000;
  buff[6] &= ~0b10110000;

  buff[8] |= 0b10000000;
  buff[8] &= ~0b01000000;

  const parts = [
    byteStr(buff, 0, 4),
    "-",
    byteStr(buff, 4, 2),
    "-",
    byteStr(buff, 6, 2),
    "-",
    byteStr(buff, 8, 2),
    "-",
    byteStr(buff, 10, 6),
  ];

  return parts.join("");
}

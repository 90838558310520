//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  computed: {
    order() {
      const order = this.$store.getters["orders/orderById"](this.id);
      return order;
    },
  },
  props: {
    id: {
      required: true,
      type: [String, Number],
    },
  },
  data: () => ({
    customer: null,
  }),
  created() {
    this.loadCustomer();
  },
  methods: {
    async loadCustomer() {
      this.customer = await this.$store.dispatch("orders/loadOrderCustomer", this.id);
    },
    returnBack() {
      this.$f7.views.main.router.back();
    },
  },
  filters: {
    ingredients(items) {
      return items.map(item => item.charAt(0).toUpperCase() + item.slice(1)).join(", ");
    },
    extras: items => items.map(item => item.name).join(", "),
    size(item) {
      const name = item.variationId > 0 ? `${item.name} (${item.size})` : `${item.name} ${item.unit}`;
      return name;
    },
  },
};

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "f7-app",
    { attrs: { params: _vm.f7Params } },
    [
      _c(
        "f7-panel",
        { attrs: { right: "", cover: "" } },
        [_c("f7-view", { attrs: { url: "/panel-right/" } })],
        1
      ),
      _vm._v(" "),
      _c("f7-view", {
        attrs: { id: "main-view", "push-state": false, url: "/", main: "" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
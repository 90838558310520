//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import logger from "./loggerInstance";
import routes from "./routes";

export default {
  created() {
    logger.log("INFO", "app.vue created()", {
      wasDiscarded: document.wasDiscarded,
      navigationType: this.getNavigationType(),
    });
    window.addEventListener("app-authenticated", this.authUpdate, false);
    window.addEventListener("app-auth-expired", this.authExpired, false);
  },
  data: () => ({
    f7Params: {
      touch: {
        mdTouchRipple: false,
        iosTouchRipple: false,
      },
      routes
    }
  }),
  methods: {
    authExpired() {
      this.$store.dispatch("stopTimeUpdate");
      this.$f7.views.main.router.navigate("/");
    },
    authUpdate() {
      console.info("authUpdate()");
      if (!this.$store.getters["auth/isLoggedIn"]) return;
      logger.addMetaData("userID", this.$store.state.auth.userId);
      this.$store.dispatch("orders/loadBar");
      this.$store.dispatch("startTimeUpdate");
      this.$store.dispatch("orders/load");
    },
    getNavigationType() {
      if (!("performance" in window)) return "unknown";
      if (typeof window.performance.getEntriesByType !== "function") return "unknown";
      const entries = window.performance.getEntriesByType("navigation");
      return entries.map(item => item.type).join("|");
    },
  },
};

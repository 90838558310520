var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("f7-page", [
    _c(
      "div",
      { staticClass: "page-wrapper" },
      [
        _c("Header"),
        _vm._v(" "),
        _c("div", { staticClass: "page-content" }, [
          _c(
            "div",
            { staticClass: "report" },
            [
              _c(
                "button",
                { staticClass: "btn-return", on: { click: _vm.goBack } },
                [_vm._v("Takaisin tilauksiin")]
              ),
              _vm._v(" "),
              _c("h2", { staticClass: "report-heading" }, [
                _vm._v("Näytä raportti"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "report-options" },
                [
                  _c("Radio", {
                    attrs: {
                      value: "currentDay",
                      label: "Nykyiseltä päivältä",
                    },
                    model: {
                      value: _vm.reportType,
                      callback: function ($$v) {
                        _vm.reportType = $$v
                      },
                      expression: "reportType",
                    },
                  }),
                  _vm._v(" "),
                  _c("Radio", {
                    attrs: {
                      value: "currentMonth",
                      label: "Nykyiseltä kuukaudelta",
                    },
                    model: {
                      value: _vm.reportType,
                      callback: function ($$v) {
                        _vm.reportType = $$v
                      },
                      expression: "reportType",
                    },
                  }),
                  _vm._v(" "),
                  _c("Radio", {
                    attrs: { value: "date", label: "Tietyltä päivältä" },
                    model: {
                      value: _vm.reportType,
                      callback: function ($$v) {
                        _vm.reportType = $$v
                      },
                      expression: "reportType",
                    },
                  }),
                  _vm._v(" "),
                  _c("Radio", {
                    attrs: { value: "range", label: "Valitulta aikajaksolta" },
                    model: {
                      value: _vm.reportType,
                      callback: function ($$v) {
                        _vm.reportType = $$v
                      },
                      expression: "reportType",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "report-extra-options" },
                [
                  _vm.reportType === "date"
                    ? _c("label", { staticClass: "extra-option" }, [
                        _c("span", { staticClass: "extra-option-label" }, [
                          _vm._v("Päivämäärä"),
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.specificDate,
                              expression: "specificDate",
                            },
                          ],
                          attrs: { type: "date" },
                          domProps: { value: _vm.specificDate },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.specificDate = $event.target.value
                            },
                          },
                        }),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.reportType === "range"
                    ? [
                        _c("label", { staticClass: "extra-option" }, [
                          _c("span", { staticClass: "extra-option-label" }, [
                            _vm._v("Alku"),
                          ]),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.startDate,
                                expression: "startDate",
                              },
                            ],
                            attrs: { type: "date" },
                            domProps: { value: _vm.startDate },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.startDate = $event.target.value
                              },
                            },
                          }),
                        ]),
                        _vm._v(" "),
                        _c("label", { staticClass: "extra-option" }, [
                          _c("span", { staticClass: "extra-option-label" }, [
                            _vm._v("Päättyminen"),
                          ]),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.endDate,
                                expression: "endDate",
                              },
                            ],
                            attrs: { type: "date" },
                            domProps: { value: _vm.endDate },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.endDate = $event.target.value
                              },
                            },
                          }),
                        ]),
                      ]
                    : _vm._e(),
                ],
                2
              ),
              _vm._v(" "),
              _c("div", { staticClass: "report-actions" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn-view",
                    attrs: { disabled: !_vm.canShow },
                    on: { click: _vm.download },
                  },
                  [_vm._v("Näytä raportti")]
                ),
              ]),
              _vm._v(" "),
              _vm.reportData
                ? _c("ReportTable", { attrs: { data: _vm.reportData } })
                : _vm._e(),
            ],
            1
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
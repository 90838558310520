//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapState} from "vuex";

export default {
  computed: {
    ...mapState("orders", ["ordersPaused"]),
  },
  methods: {
    viewReports() {
      this.$f7router.navigate("/reports/");
    },
    logout() {
      this.$store.dispatch("auth/logout");
    },
    pauseOrders(pause) {
      this.$store.dispatch("orders/setOrdersPaused", pause);
    },
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  data: () => ({
    username: "",
    password: ""
  }),
  methods: {
    async login() {

      try {
        await this.$store.dispatch("auth/login", {username: this.username, password: this.password});
        const toastTop = this.$f7.toast.create({
            text: "Tervetuloa!",
            position: "center",
            closeTimeout: 1000,
          });

        toastTop.open();
        setTimeout(() => {
          this.$f7router.navigate("/orders/");
          this.username = "";
          this.password = "";
        }, 1000);
      } catch(e) {
        this.$f7.dialog.alert(this.$t("LOGIN_FAILED"), this.$t("LOGIN_FAILED_TITLE"));
        console.log(error.response);
      }
    },
    forgetPw() {
      this.$f7.dialog.prompt("Anna sähköpostiosoite johon salasana lähetetään.", "Salasana palautus", async email => {
        try {
          await this.$store.dispatch("auth/requestPasswordReset", email);
          this.$f7.dialog.alert("Salasana saapuu sähköpostiisi muutaman minuutin kuluessa.", "Salasana palautettu");
        } catch(e) {
          this.$f7.dialog.alert(`Tarkista että kyseinen sähköpostiosoite on oikein: ${email}`, "Palautus ei onnistunut!");
        }
      });
    },
    setLang(lang) {
      this.$i18n.locale = lang;
      localStorage.setItem("currentLanguage", lang);
    },
  },
};

import {Logger} from "./logger";
import * as util from "./util";

const sessionID = util.genUUID();

const url = "https://jjbapp.fi/wp-json/jjb/v1/logs";
const logger = new Logger("app", url, 30 * 1000);
logger.addMetaData("sessionID", sessionID);

export default logger;

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {ErrorMixin} from "@/mixins";
import {format as formatDate} from "date-fns";

export default {
  mixins: [ErrorMixin],
  data: () => ({
    selectedRewards: {},
  }),
  props: {
    columns: {
      type: Number,
      default: 2,
    },
    customer: {
      required: true,
      type: Object,
    },
  },
  computed: {
    otherRewards() {
      return this.passiveRewards.map(r => r.reward.name).join(", ");
    },
    canRedeem() {
      const valid = Object.values(this.selectedRewards).filter(v => v);
      return valid.length > 0;
    },
    rewards() {
      return this.customer.rewards;
    },
    passiveRewards() {
      return this.rewards.filter(r => r.reward.passive);
    },
    usableRewards() {
      return this.rewards.filter(r => !r.reward.passive);
    },
  },
  mounted() {
    this.$nextTick(() => this.setColumns(this.columns));
  },
  methods: {
    rewardValidity(d) {
      const date = new Date(d);
      return formatDate(date, "dd.MM.yyyy");
    },
    confirm(title, msg) {
      return new Promise(resolve => {
        this.$f7.dialog.confirm(msg, title, () => resolve(true), () => resolve(false));
      });
    },
    redeemSelected() {
      this.$f7.dialog.alert(
        "Etujen käyttö kassan web-sovelluksesta ei ole enää mahdollista. Asiakkaan tulee käyttää etu sovelluksesta.",
        "Virhe"
      );
    },
    removeRewards(ids) {
      for (const id of ids) {
        const index = this.customer.rewards.findIndex(r => r.id === id);
        if (index < 0) continue;
        this.customer.rewards.splice(index, 1);
      }
    },
    toggleReward(reward) {
      const id = reward.id;
      const current = Boolean(this.selectedRewards[id]);
      this.$set(this.selectedRewards, id, !current);
    },
    isRewardSelected(reward) {
      return Boolean(this.selectedRewards[reward.id]);
    },
    setColumns(n) {
      const el = this.$refs.listContainer;
      el.style.setProperty("--reward-list-columns", n);
    },
  },
};

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "customer-card" }, [
    _c(
      "div",
      { staticClass: "customer-card-info" },
      [
        _c("h4", { staticClass: "info-heading" }, [
          _vm._v(_vm._s(_vm.$t("CURRENT_CUSTOMER"))),
        ]),
        _vm._v(" "),
        !_vm.minimized
          ? [
              _c("span", { staticClass: "customer-info-text name" }, [
                _vm._v(
                  _vm._s(_vm.customer.firstName) +
                    " " +
                    _vm._s(_vm.customer.lastName)
                ),
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "customer-info-text" }, [
                _vm._v(_vm._s(_vm.customer.phone)),
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "customer-info-text coconuts" }, [
                _vm._v(_vm._s(_vm.customer.loyalty.points) + " kookosta"),
              ]),
            ]
          : _vm._e(),
      ],
      2
    ),
    _vm._v(" "),
    !_vm.minimized
      ? _c(
          "div",
          { staticClass: "customer-card-rewards" },
          [_c("Rewards", { attrs: { customer: _vm.customer, columns: 3 } })],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "customer-card-actions" }, [
      _c(
        "button",
        {
          staticClass: "action-btn minimize",
          on: { click: _vm.toggleMinimized },
        },
        [
          _vm.minimized
            ? [_vm._v("\n        Näytä\n      ")]
            : [_vm._v("\n        Piilota\n      ")],
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "action-btn close",
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [_vm._v("Sulje")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import errorMessages from "@/errorMessages.json";
import {getProp} from "./util";

export const ErrorMixin = {
  methods: {
    displayError(err) {
      const code = getProp(err, "response.data.code");
      const msg = errorMessages[code];
      console.info("displayError", code);
      if(msg) {
        this.$f7.dialog.alert(msg, "Virhe");
      } else {
        let errMsg = errorMessages["GENERIC"];
        if (code) errMsg += `\nKoodi: ${code}`;
        this.$f7.dialog.alert(errMsg, "Virhe");
      }
    }
  }
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Rewards from "@/components/Rewards.vue";

export default {
  components: {
    Rewards,
  },
  data: () => ({
    minimized: false,
  }),
  props: {
    customer: {
      type: Object,
      required: true,
    },
  },
  methods: {
    toggleMinimized() {
      this.minimized = !this.minimized;
    },
    open() {
      this.$f7router.navigate("/customer/", {props: {customer: this.customer}});
    },
  },
};

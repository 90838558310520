var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("f7-page", { staticClass: "welcome-page" }, [
    _c("div", { staticClass: "action-container" }, [
      _c(
        "button",
        { staticClass: "continue-button", on: { click: _vm.proceed } },
        [_vm._v(_vm._s(_vm.$t("CONTINUE_APP")))]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "f7-popup",
    {
      ref: "popup",
      staticClass: "reject-modal",
      attrs: { "close-by-backdrop-click": false, "close-on-escape": false },
    },
    [
      _c(
        "div",
        { staticClass: "reject-modal-inner" },
        [
          _c("div", { staticClass: "row close-row" }, [
            _c("button", {
              staticClass: "close-btn",
              on: { click: _vm.close },
            }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row row-heading" }, [
            _vm._v(_vm._s(_vm.$t("REJECT_ORDER"))),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row row-info" }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm.$t("PICKUP")) +
                " " +
                _vm._s(_vm._f("date")(_vm.order.time)) +
                "\n    "
            ),
          ]),
          _vm._v(" "),
          _vm._l(_vm.order.items, function (item) {
            return _c("div", { staticClass: "row row-info" }, [
              _vm._v("\n      " + _vm._s(item.name) + "\n    "),
            ])
          }),
          _vm._v(" "),
          _c("div", { staticClass: "row send-row" }, [
            _c(
              "button",
              {
                staticClass: "send-btn",
                attrs: { disabled: _vm.reason === null },
                on: { click: _vm.send },
              },
              [_vm._v("Hylkää tilaus")]
            ),
          ]),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
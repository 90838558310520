//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import RejectModal from "./RejectModal";
import {ErrorMixin} from "@/mixins";

export default {
  mixins: [ErrorMixin],
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  components: {
    RejectModal,
  },
  data: () => ({
    showRejectModal: false,
  }),
  filters: {
    price: v => {
      const num = parseFloat(v);
      return num.toFixed(2).replace(".", ",");
    },
    rewards: v => v.map(i => i.reward.name).join(", "),
  },
  computed: {
    isImminent() {
      const orders = this.$store.getters["orders/imminentIds"];

      return orders.includes(this.order.id);
    },
    rewards() {
      const rewards = [];
      if (Array.isArray(this.order.rewards)) {
        rewards.push(...this.order.rewards);
      }

      if (Array.isArray(this.order.passiveRewards)) {
        rewards.push(...this.order.passiveRewards);
      }

      return rewards;
    },
  },
  methods: {
    hasRewards(item) {
      if (item.appliedRewards.length > 0) return true;

      for (const extra of item.extras) {
        if (extra.appliedRewards.length > 0) return true;
      }

      return false;
    },
    formatAppliedRewards(item) {
      const rewards = [...item.appliedRewards];
      for (const extra of item.extras) {
        rewards.push(...extra.appliedRewards);
      }

      return rewards.map(r => r.label).join(", ");
    },
    confirm() {
      return new Promise(resolve => {
        this.$f7.dialog.alert("Muistithan lisätä ostoksen kassaan", "Huomio", resolve);
      });
    },
    async setReady() {
      await this.setStatus("ready");
      await this.confirm();
    },
    async setStatus(status) {
      this.$f7.dialog.preloader("");
      try {
        await this.$store.dispatch("orders/updateStatus", {id: this.order.id, status});
      } catch (e) {
        this.displayError(e);
      } finally {
        this.$f7.dialog.close();
      }
    },
    goToOrder() {
      this.$f7router.navigate(`/orderInfo/${this.order.id}`);
    },
    async accept() {
      this.$f7.dialog.preloader("");
      try {
        await this.$store.dispatch("orders/accept", this.order.id);
      } catch (e) {
        this.displayError(e);
        this.$store.dispatch("orders/load");
      } finally {
        this.$f7.dialog.close();
      }
    },
    reject() {
      this.showRejectModal = true;
    },
  },
};

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("nav", { staticClass: "app-nav" }, [
    _c("ul", { staticClass: "tabs" }, [
      _c(
        "li",
        {
          staticClass: "tab",
          class: _vm.tabClass("pending"),
          on: {
            click: function ($event) {
              return _vm.setTab("pending")
            },
          },
        },
        [
          _c("span", { staticClass: "tab-name" }, [
            _vm._v(_vm._s(_vm.$t("ORDERS_NEW"))),
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "counter" }, [
            _vm._v(_vm._s(_vm.pending.length)),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "li",
        {
          staticClass: "tab",
          class: _vm.tabClass("accepted"),
          on: {
            click: function ($event) {
              return _vm.setTab("accepted")
            },
          },
        },
        [
          _c("span", { staticClass: "tab-name" }, [
            _vm._v(_vm._s(_vm.$t("ORDERS_QUEUED"))),
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "counter" }, [
            _vm._v(_vm._s(_vm.accepted.length)),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "li",
        {
          staticClass: "tab",
          class: _vm.tabClass("processing"),
          on: {
            click: function ($event) {
              return _vm.setTab("processing")
            },
          },
        },
        [
          _c("span", { staticClass: "tab-name" }, [
            _vm._v(_vm._s(_vm.$t("ORDERS_PROCESSING"))),
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "counter" }, [
            _vm._v(_vm._s(_vm.processing.length)),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "li",
        {
          staticClass: "tab",
          class: _vm.tabClass("ready"),
          on: {
            click: function ($event) {
              return _vm.setTab("ready")
            },
          },
        },
        [
          _c("span", { staticClass: "tab-name" }, [
            _vm._v(_vm._s(_vm.$t("ORDERS_READY"))),
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "counter" }, [
            _vm._v(_vm._s(_vm.ready.length)),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }